"use strict";

import Vue from 'vue';
import axios from "axios";
// import store from '../store'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.baseURL || process.env.apiUrl || process.env.VUE_APP_API,
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      let token = ''
      if (token.length > 0) {
        config.headers['AUTH-TOKEN'] = token
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      const code = response.data.code;
      if (code < 200 || code > 300) {
        // Message(response.data.message);
        if (code === 1201) {
          // 未登录
        }
        return Promise.reject('error');
      } else {
        return response.data;
      }
    },
    function (error) {
      console.log('ERROR',error)
      // Do something with response error
      let code = 0;
      try {
        code = error.response.data.status;
      } catch (e) {
        if (error.toString().indexOf('Error: timeout') !== -1) {
          // 网络请求超时
          return Promise.reject(error);
        }
        if (error.toString().indexOf('Error: Network Error') !== -1) {
          // 网络请求错误
          return Promise.reject(error);
        }
      }
      if (code === 401) {
        // MessageBox('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        //   confirmButtonText: '重新登录',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('LogOut').then(() => {
        //     location.reload(); // 为了重新实例化vue-router对象 避免bug
        //   });
        // })
      } else if (code === 403) {
        // Notification.error({
        //   title: '错误',
        //   message: '网络请求错误',
        //   duration: 2500,
        // });
      } else {
        // const errorMsg = error.response.data.message;
        console.log(error);
        
        // if (errorMsg !== undefined) {
          // Notification.error({
          //   message: errorMsg,
          //   duration: 2500,
          // });
        // }
      }
      return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
