<template>
    <div id="app">
        <router-view v-if="isRouterAlive" v-wechat-title="$route.meta.title"></router-view>
    </div>
</template>

<script>
    export default {
        name: 'APP',
        provide() {
            return {
                reload: this.reload
            }
        },
        data() {
            return {
                isRouterAlive: true
            }
        },
        methods: {
            reload() {
                this.isRouterAlive = false;
                this.$nextTick(function () {
                    this.isRouterAlive = true
                })
            }
        }
    }
</script>

<style lang="scss">
    @import "style/common";

    #app {
        //font-family: Avenir, Helvetica, Arial, sans-serif;//
        //-webkit-font-smoothing: antialiased;//
        //-moz-osx-font-smoothing: grayscale;//
        //text-align: center;//
        //color: $c_main;//
        @include rect(100%, 100%);
        background-color: #f8f8f8;
    }

    .container {
        .content {
            @include flex()
            @include overflow(auto)
            @include rect(100%, 100%);
        }

        .footer {
            height: 50px;
            background: #ffffff;
        }

        @include rect(100%, 100%)
        @include flexbox()
        @include flex-direction(column)
        @include justify-content(space-between)
    }
</style>
