const userInfo = 'userInfo'

export function getUser() {
    return localStorage.getItem(userInfo) ? JSON.parse(localStorage.getItem(userInfo)) : {}
}

export function setUser(user) {
    return localStorage.setItem(userInfo, JSON.stringify(user))
}

export function removeUser() {
    return localStorage.removeItem(userInfo)
}

export function isAdmin() {
    let user = getUser();
    return user.isJixiaoAdmin
}
