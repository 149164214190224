import Vue from 'vue'
import App from './App.vue'
import './plugins/axios' // axios
import '@/icons'
import router from './router'
import store from './store'
import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle)
Vue.config.productionTip = false

import './utils/rem'
import './utils/filter'
import dayjs from "dayjs"

import {
    RadioGroup,
    Radio,
    Form,
    Button,
    Picker,
    Tabbar,
    TabbarItem,
    NavBar,
    Cell,
    CellGroup,
    Icon,
    Tag,
    Collapse,
    CollapseItem,
    Divider,
    Popup,
    Notify,
    Search,
    Tab,
    Field,
    DatetimePicker,
    Calendar,
    Tabs,
    Toast,
    Slider
} from 'vant';

Vue.use(Tabs).use(Tab).use(Notify).use(Radio).use(RadioGroup)
    .use(Search).use(Picker).use(Popup).use(Button)
    .use(Tabbar).use(TabbarItem).use(NavBar).use(Cell)
    .use(CellGroup).use(Icon).use(Tag).use(Collapse)
    .use(Form).use(Field).use(DatetimePicker).use(Calendar)
    .use(CollapseItem).use(Divider).use(Toast).use(Slider);

Vue.prototype.$dayJs = dayjs

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
