import Vue from 'vue'
import VueRouter from 'vue-router'
import {getUser} from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '',
        redirect: '/gourd/performance-score',
        component: () => import(/* webpackChunkName: "about" */ '../views/Gourd/index'),
        children: [
            {
                path: '/gourd/proxy',
                name: '登录对接中',
                meta: {title: '恩派绩效考核', login: false},
                component: () => import(/* webpackChunkName: "about" */ '../views/proxy.vue')
            },
            {
                path: '/gourd/performance-score',
                name: '绩效评分',
                meta: {title: '恩派绩效考核', login: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/PerformanceScore/index.vue')
            },
            {
                path: '/gourd/performance-management',
                name: '管理',
                meta: {title: '管理', login: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/Management/index.vue')
            },
            {
                path: '/gourd/performance-detail',
                name: '绩效详情',
                meta: {title: '绩效详情', login: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/PerformanceScore/PerformanceDetail.vue')
            },
            {
                path: '/gourd/self-assessment.vue',
                name: '自我评分',
                meta: {title: '自我评分', login: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/PerformanceScore/SelfAssessment.vue')
            },
            {
                path: '/gourd/boss-assessment.vue',
                name: '主管评分',
                meta: {title: '主管评分', login: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/PerformanceScore/BossAssessment.vue')
            },
            {
                path: '/gourd/performance-admin',
                name: '绩效管理',
                meta: {title: '绩效管理', login: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/Management/ManagementAdmin.vue')
            },
            {
                path: '/gourd/assessment-details.vue',
                name: '评分详情',
                meta: {title: '评分详情', login: true},
                component: () => import(/* webpackChunkName: "about" */ '../views/Management/ScoreDetails.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//拦截器
router.beforeEach((to, from, next) => {
    if (to.meta.login) {
        //判断是否登录
        let user = getUser()
        if (!user.userid) {
            next('/gourd/proxy')
        } else {
            next()
        }
    } else {
        next()//执行进入路由，如果不写就不会进入目标页
    }
})

export default router
