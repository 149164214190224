const user = {
  state: {
    userInfo: {},
    token: '',
    isLogin: false
  },

  mutations: {
    SET_USER: (state, userInfo) => {
      state.userInfo = userInfo
    },

    SET_TOKEN: (state, token) => {
      state.token = token
    },

    SET_LOGIN_STATE: (state, loginState) => {
      state.isLogin = loginState
      if (loginState == false) {
        state.userInfo = null
        state.token = ''
      }
    }
  },

  actions: {

    //登录
    Login ({ commit }, params) {
      return new Promise((resolve, reject) => {
        window.axios.post("user/login", params).then(res => {
          const token = res.data.token;
          const userInfo = res.data;
          if (token) {
            commit('SET_TOKEN', token)
            commit('SET_LOGIN_STATE', true)
          }
          if (userInfo) {
            commit('SET_USER', userInfo)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut ({ commit }) {
      return new Promise((resolve) => {
        commit('SET_LOGIN_STATE', false)
        resolve()
      })
    },

    //更新用户信息
    UpdateUserInfo ({ commit }, userInfo) {
      commit('SET_USER', userInfo)
    }
  }
}

export default user